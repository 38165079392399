import React, { ReactElement } from "react"
import { Layout } from "../components/Layout"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const Emphasize: React.FunctionComponent = ({ children }) => (
  <span className="text-primary-600 font-bold">{children}</span>
)
const AboutMe = ({ data }: any): ReactElement => {
  return (
    <Layout>
      <div className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />

          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <GatsbyImage
                      className="rounded-lg shadow-lg object-cover object-center"
                      fluid={data.image.childImageSharp.fluid}
                      alt="Christian on stage at international javascript conference"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl mb-4">
                <p>
                  <span>My </span>
                  <span className="text-primary-600">talks</span>
                </p>
                <p>
                  and <span className="text-primary-600 ">articles</span>
                </p>
              </h1>

              <div
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: data.portfolio.edges[0].node.childMarkdownRemark.html,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function AboutMe2({ data }: any): ReactElement {
  console.log()
  return (
    <Layout>
      <main className="h-screen">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <p>
                <span className="block xl:inline">My </span>
                <span className="block text-primary-600 xl:inline">talks</span>
              </p>
              <p>
                and{" "}
                <span className="block text-primary-600 xl:inline">
                  articles
                </span>
              </p>
            </h1>
            <div
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: data.portfolio.edges[0].node.childMarkdownRemark.html,
              }}
            ></div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={IJSChristian}
            alt="Christian on stage at international javascript conference"
          />
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioQuery {
    portfolio: allFile(
      filter: {
        name: { eq: "portfolio" }
        sourceInstanceName: { eq: "markdown" }
      }
    ) {
      edges {
        node {
          name
          childMarkdownRemark {
            html
          }
        }
      }
    }
    image: file(
      name: { eq: "ijs-christian" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default AboutMe
